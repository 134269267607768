import fetch, { Headers } from 'node-fetch'
import React from 'react'
import { OO_BE_URL_V1 } from '../config'
import {
  ApiOrder,
  ShopInfo,
  Basket,
  CustomerInfo,
  MenuByCategories,
  MultiSelectModifier,
  SelectModifier,
  ApiInfo,
  ApiShopInfo,
} from '../models/domain'
import { round } from '../util'

type SubmitOrderPayload = Basket & { customerInfo: CustomerInfo }

function getAuthHeaders(token: string) {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer ' + token)

  return headers
}

export async function getOrders(token: string) {
  const headers = getAuthHeaders(token)

  const getRes = await fetch(`${OO_BE_URL_V1}merchant/orders`, {
    method: 'GET',
    headers,
  })

  const { orders }: { orders: ApiOrder[] } = await getRes.json()
  return orders
}

export async function confirmOrder(orderId: string, estimation: React.MutableRefObject<number>, token: string) {
  const headers = getAuthHeaders(token)

  await fetch(`${OO_BE_URL_V1}merchant/approve/${orderId}`, {
    method: 'POST',
    headers,
    body: JSON.stringify({estimation: estimation.current})
  })
}

export async function rejectOrder(orderId: string, reasonRef: React.MutableRefObject<string>, token: string) {
  const headers = getAuthHeaders(token)

  await fetch(`${OO_BE_URL_V1}merchant/reject/${orderId}`, {
    method: 'POST',
    headers,
    body: JSON.stringify({reason: reasonRef.current})
  })
}

export async function markAsReadyForPickUp(orderId: string, token: string) {
  const headers = getAuthHeaders(token)
  await fetch(`${OO_BE_URL_V1}merchant/readyforpickup/${orderId}`, {
    method: 'POST',
    headers,
  })
}

export async function markAsDelivered(orderId: string, token: string) {
  const headers = getAuthHeaders(token)

  await fetch(`${OO_BE_URL_V1}merchant/delivered/${orderId}`, {
    method: 'POST',
    headers,
  })
}

export async function submitOrder(payload: SubmitOrderPayload, token: string) {
  const clone: Basket = JSON.parse(JSON.stringify(payload))
  clone.total = round(clone.total)

  const headers = new Headers()
  headers.append('Content-Type', 'application/json')

  const postRes = await fetch(`${OO_BE_URL_V1}order`, {
    method: 'POST',
    body: JSON.stringify(clone),
    headers,
  })

  const { checkoutUrl } = await postRes.json()
  return checkoutUrl
}

export async function getOrder(orderId: string) {
  const orderRes = await fetch(`${OO_BE_URL_V1}order/${orderId}`)
  const order: ApiOrder = await orderRes.json()
  return order
}

export async function getApiInfo() {
  const infoRes = await fetch(`${OO_BE_URL_V1}info`)
  const info: ApiInfo = await infoRes.json()
  return info
}

export async function getShopData(shopId: string): Promise<ShopInfo> {
  const shopRes = await fetch(`${OO_BE_URL_V1}shop/${shopId}`)
  const {
    merchant,
    store,
    menus,
    modifiers,
    products,
    isOpenAtm,
  }: ApiShopInfo = await shopRes.json()

  const menuByCategories: MenuByCategories = menus.map((m) => {
    return {
      categoryName: m.name,
      items: m.menuProducts.map((mp) => {
        const apiProduct = products.find((p) => p.id === mp.product_id)!
        const productModifiers = modifiers
          .filter((m) => apiProduct.modifierIds.includes(m.id))
          .map((m) => {
            return {
              ...m,
              options: m.options.map(({ label, price, id }) => {
                return { label, id, price: +price }
              }),
            }
          })

        const singleSelectModifiers: SelectModifier[] = productModifiers
          .filter((m) => !m.multiSelect)
          .map((m) => {
            return {
              ...m,
              selected: m.options[0],
            }
          })

        const multiSelectModifiers: MultiSelectModifier[] = productModifiers
          .filter((m) => m.multiSelect)
          .map((m) => {
            return {
              ...m,
              selected: [],
            }
          })

        return {
          ...apiProduct,
          basePrice: +apiProduct.basePrice,
          qty: 0,
          singleSelectModifiers,
          multiSelectModifiers,
        }
      }),
    }
  })

  return {
    isOpenAtm,
    merchant,
    store: { ...store, calendarParsed: JSON.parse(store.calendar) },
    menuByCategories,
  }
}
