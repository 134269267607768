import { DateTime } from 'luxon'

export const OO_BE_URL =
  process.env.REACT_APP_OO_BE_URL ?? 'https://onlineorder-master.herokuapp.com'
export const OO_BE_URL_V1 = `${OO_BE_URL}/v1/`
export const QITEA_HOURS = {
  PHYSICAL_START: DateTime.now().set({ hour: 12 }),
  PHYSICAL_END: DateTime.now().set({ hour: 21, minute: 30 }),
  OO_START: DateTime.now().set({ hour: 12, minute: 30 }),
  OO_END: DateTime.now().set({ hour: 21 }),
}

// auth0
const ooRestaurantFrontClientIdStaging = 'vLaTYv82gkwytk6p77kYcNQP7A4JSgBx'
const ooBeAudienceStaging = 'https://onlineorder-master.herokuapp.com/'

export const REACT_APP_AUTH0_OO_BE_AUDIENCE = process.env.REACT_APP_AUTH0_OO_BE_AUDIENCE ?? ooBeAudienceStaging
export const AUTH0_DOMAIN = 'smartdining.eu.auth0.com'
export const REACT_APP_AUTH0_OO_RESTAURANT_FRONT_CLIENTID =
  process.env.REACT_APP_AUTH0_OO_RESTAURANT_FRONT_CLIENTID ?? ooRestaurantFrontClientIdStaging
