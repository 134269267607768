import { useAuth0 } from '@auth0/auth0-react'
import { useRef, useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { ApiOrder, ConfirmationModalOptions } from '../models/domain'
import { confirmOrder, rejectOrder } from '../services/oo-be'
import { ConfirmationModal } from './Modals'
import { OrderReview } from './OrderReview'

const rejectionReasons = ['Out of stock', 'Queue is full and shop is closing soon']
const estimationOptionMinutes = [15, 30, 45]

export function IncomingOrder({
  orders,
  postActionCb,
}: {
  orders: ApiOrder[]
  postActionCb: Function
}) {
  const { getAccessTokenSilently } = useAuth0()

  const [confirmationModalOpt, setConfirmationModalOpt] =
    useState<ConfirmationModalOptions>({})

  const [rejectReason, setRejectReason] = useState(rejectionReasons[0])
  const [estimation, setEstimation] = useState(estimationOptionMinutes[0])
  const rejectReasonRef = useRef(rejectReason)
  const estimationRef = useRef(estimation)

  const onCloseClick = async () => {
    await postActionCb()
    setConfirmationModalOpt({})
  }

  const onAcceptClick = (order: ApiOrder) => {
    async function apiAcceptThenShowResult() {
      setConfirmationModalOpt({ show: true, showSpinner: true })
      await confirmOrder(order.id, estimationRef, await getAccessTokenSilently())
      setConfirmationModalOpt({
        show: true,
        showSpinner: false,
        done: true,
        doneText: `Succesfully accepted order #${order.orderNo}`,
        close: onCloseClick,
      })
    }

    setConfirmationModalOpt({
      show: true,
      question: `Click CONFIRM to accept order #${order.orderNo}`,
      confirm: apiAcceptThenShowResult,
      cancel: () => setConfirmationModalOpt({ show: false }),
    })
  }

  const onRejectClicked = (order: ApiOrder) => {
    async function apiRejectThenShowResult() {
      setConfirmationModalOpt({ show: true, showSpinner: true })
      await rejectOrder(
        order.id,
        rejectReasonRef,
        await getAccessTokenSilently()
      )
      setConfirmationModalOpt({
        show: true,
        showSpinner: false,
        done: true,
        doneText: `Succesfully rejected order #${order.orderNo}`,
        close: onCloseClick,
      })
    }

    setConfirmationModalOpt({
      show: true,
      question: `Click CONFIRM to REJECT order #${order.orderNo}`,
      showRejectDropdown: true,
      confirm: apiRejectThenShowResult,
      cancel: () => setConfirmationModalOpt({ show: false }),
    })
  }

  /**
   * Reject reason dropdown on reject
   * Estimation dropdown on accept
   */
  const confirmationMetaData = confirmationModalOpt.showRejectDropdown ? (
    <>
      Reject reason:
      <DropdownButton
        onSelect={(value) => {
          const newReason = value!.replace('#', '')
          rejectReasonRef.current = newReason
          setRejectReason(newReason)
        }}
        id="reject-reason-dropdown"
        title={rejectReason}
      >
        {rejectionReasons.map((reason) => (
          <Dropdown.Item href={'#' + reason}>{reason}</Dropdown.Item>
        ))}
      </DropdownButton>
    </>
  ) : (
    <>
      Estimation
      <DropdownButton
        onSelect={(value) => {
          const newEst = parseInt(value!.replace('#', ''))
          estimationRef.current = newEst
          setEstimation(newEst)
        }}
        id="estimation-dropdown"
        title={`${estimation} minutes`}
      >
        {estimationOptionMinutes.map((est) => (
          <Dropdown.Item href={'#' + est}>{est} minutes</Dropdown.Item>
        ))}
      </DropdownButton>
    </>
  )

  const bgClass = orders.length > 0 ? 'bg-danger' : 'bg-warning'
  return (
    <>
      <h2 className={bgClass + ' text-center py-3'}>
        INCOMING ORDERS ({orders.length})
      </h2>
      <ConfirmationModal {...confirmationModalOpt}>
        {confirmationMetaData}
      </ConfirmationModal>
      {orders.map((order) => {
        return (
          <OrderReview
            order={order}
            onAccept={onAcceptClick}
            onReject={onRejectClicked}
          ></OrderReview>
        )
      })}
    </>
  )
}
